@font-face {
  font-family: 'theme-icon';
  src:  url('fonts/theme-icon.eot?szogme');
  src:  url('fonts/theme-icon.eot?szogme#iefix') format('embedded-opentype'),
    url('fonts/theme-icon.ttf?szogme') format('truetype'),
    url('fonts/theme-icon.woff?szogme') format('woff'),
    url('fonts/theme-icon.svg?szogme#theme-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'theme-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bell:before {
  content: "\e900";
}
.icon-camera:before {
  content: "\e901";
}
.icon-book:before {
  content: "\e902";
}
.icon-copy:before {
  content: "\e903";
}
.icon-download-solid:before {
  content: "\e904";
}
.icon-add-user:before {
  content: "\e905";
}
.icon-apple-pay:before {
  content: "\e906";
}
.icon-apple:before {
  content: "\e907";
}
.icon-arrow-left:before {
  content: "\e908";
}
.icon-arrow-right:before {
  content: "\e909";
}
.icon-ban:before {
  content: "\e90a";
}
.icon-bar:before {
  content: "\e90b";
}
.icon-bitcoin:before {
  content: "\e90c";
}
.icon-calculator-exchange:before {
  content: "\e90d";
}
.icon-cancel:before {
  content: "\e90e";
}
.icon-check:before {
  content: "\e90f";
}
.icon-chevron-down:before {
  content: "\e910";
}
.icon-chevron-left:before {
  content: "\e911";
}
.icon-chevron-right:before {
  content: "\e912";
}
.icon-chevron-up:before {
  content: "\e913";
}
.icon-comment-dots:before {
  content: "\e914";
}
.icon-contacts:before {
  content: "\e915";
}
.icon-news:before {
  content: "\e965";
}
.icon-credit-card:before {
  content: "\e916";
}
.icon-danger-triangle:before {
  content: "\e917";
}
.icon-document:before {
  content: "\e918";
}
.icon-dollar:before {
  content: "\e919";
}
.icon-donate:before {
  content: "\e91a";
}
.icon-door-open:before {
  content: "\e91b";
}
.icon-end-time:before {
  content: "\e91c";
}
.icon-email:before {
  content: "\e91d";
}
.icon-exchange:before {
  content: "\e91e";
}
.icon-exclamation:before {
  content: "\e91f";
}
.icon-eye-slash:before {
  content: "\e920";
}
.icon-eye:before {
  content: "\e921";
}
.icon-facebook:before {
  content: "\e922";
}
.icon-file-invoice-dollar:before {
  content: "\e923";
}
.icon-filter:before {
  content: "\e924";
}
.icon-finish:before {
  content: "\e925";
}
.icon-google-pay:before {
  content: "\e926";
}
.icon-google-play:before {
  content: "\e927";
}
.icon-google:before {
  content: "\e928";
}
.icon-half-time:before {
  content: "\e929";
}
.icon-handshake:before {
  content: "\e92a";
}
.icon-info:before {
  content: "\e92b";
}
.icon-logs:before {
  content: "\e92c";
}
.icon-mails-all:before {
  content: "\e92d";
}
.icon-mobile:before {
  content: "\e92e";
}
.icon-money-bill:before {
  content: "\e92f";
}
.icon-money-check:before {
  content: "\e930";
}
.icon-newspaper:before {
  content: "\e931";
}
.icon-piggy-bank:before {
  content: "\e932";
}
.icon-power-off:before {
  content: "\e933";
}
.icon-question:before {
  content: "\e934";
}
.icon-quote:before {
  content: "\e935";
}
.icon-rates:before {
  content: "\e936";
}
.icon-search-plus:before {
  content: "\e937";
}
.icon-search:before {
  content: "\e938";
}
.icon-settings-cog:before {
  content: "\e939";
}
.icon-settings:before {
  content: "\e93a";
}
.icon-skype:before {
  content: "\e93b";
}
.icon-star-regular:before {
  content: "\e93c";
}
.icon-star-solid:before {
  content: "\e93d";
}
.icon-sub-menu:before {
  content: "\e93e";
}
.icon-telegram:before {
  content: "\e93f";
}
.icon-trash:before {
  content: "\e940";
}
.icon-user-shield:before {
  content: "\e941";
}
.icon-user-tag:before {
  content: "\e942";
}
.icon-user:before {
  content: "\e943";
}
.icon-users-settings:before {
  content: "\e944";
}
.icon-users-slash:before {
  content: "\e945";
}
.icon-users:before {
  content: "\e946";
}
.icon-viber:before {
  content: "\e947";
}
.icon-whatsapp:before {
  content: "\e948";
}
.icon-menu-partners:before {
  content: "\e949";
}
.icon-menu-reviews:before {
  content: "\e94a";
}
.icon-menu-exchange:before {
  content: "\e94b";
}
.icon-menu-privacy:before {
  content: "\e94c";
}
.icon-menu-rates:before {
  content: "\e966";
}
.icon-menu-news:before {
  content: "\e94d";
}
.icon-menu-manual:before {
  content: "\e94e";
}
.icon-menu-user-agreement:before {
  content: "\e94f";
}
.icon-logo-coin24:before {
  content: "\e950";
}
.icon-phone:before {
  content: "\e951";
}
.icon-city:before {
  content: "\e952";
}
.icon-funnel-dollar:before {
  content: "\e953";
}
.icon-file-image:before {
  content: "\e954";
}
.icon-edit:before {
  content: "\e955";
}
.icon-menu-city:before {
  content: "\e956";
}
.icon-clock:before {
  content: "\e957";
}
.icon-arrow-right-and-left:before {
  content: "\e958";
}
.icon-comment-pen:before {
  content: "\e959";
}
.icon-money-bill-transfer-solid:before {
  content: "\e95a";
}
.icon-menu-confirm:before {
  content: "\e95b";
}
.icon-user-check:before {
  content: "\e95c";
}
.icon-table:before {
  content: "\e95d";
}
.icon-block:before {
  content: "\e95e";
}
.icon-start:before {
  content: "\e95f";
}
.icon-language:before {
  content: "\e960";
}
.icon-profile:before {
  content: "\e961";
}
.icon-new-arrow-right:before {
  content: "\e962";
}
.icon-work-time:before {
  content: "\e963";
}
.icon-quote1:before {
  content: "\e964";
}
.icon-contact-email:before {
  content: "\e967";
}
.icon-technical-support:before {
  content: "\e968";
}
.icon-user-profile:before {
  content: "\e969";
}
.icon-log-in:before {
  content: "\e96a";
}
.icon-new-arrow-down:before {
  content: "\e96b";
}
.icon-new-arrow-up:before {
  content: "\e96c";
}
